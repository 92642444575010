import { render, staticRenderFns } from "./Search.vue?vue&type=template&id=728c3ec6&scoped=true&"
import script from "./Search.vue?vue&type=script&lang=js&"
export * from "./Search.vue?vue&type=script&lang=js&"
import style0 from "./Search.vue?vue&type=style&index=0&id=728c3ec6&lang=scss&rel=stylesheet%2Fscss&scoped=true&"
import style1 from "./Search.vue?vue&type=style&index=1&lang=scss&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "728c3ec6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {QuickGoods: require('/devcloud/slavespace/slave1-new/workspace/j_2yOt37aR/src/components/header/components/QuickGoods.vue').default,Release: require('/devcloud/slavespace/slave1-new/workspace/j_2yOt37aR/src/components/header/components/release.vue').default})
